import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Section from "../../components/Section"
import { Body, Header2 } from "../../components/Typography"

import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Button from "../../components/Button"

const ProfileGrid = styled.div`
  display: grid;
  gap: 32px;
  padding-top: 32px;
  max-width: 816px;
  align-items: start;
  margin: 0 auto;

  grid-template-columns: 1fr 2fr;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  max-width: 816px;
  margin: 0 auto;
`

function DanielPage({ data }) {
  return (
    <Layout pageTitle="About">
      <Section>
        <ButtonRow>
          <Button url="/about/arnie">
            <FiArrowLeft /> Previous Profile
          </Button>
          <Button url="/about/travis">
            Next Profile <FiArrowRight />
          </Button>
        </ButtonRow>
        <ProfileGrid>
          <GatsbyImage
            image={data.daniel.nodes[0].childImageSharp.gatsbyImageData}
          />
          <div>
            <Header2>Daniel Papp</Header2>
            <br />
            <Body>
              Daniel has a Bachelor’s degree in Business Information Systems
              with a minor in Computer Science from California Polytechnic State
              University. Through his studies, Daniel has accrued a GPA of 3.95
              and 4.0 for Information Systems and Computer Science respectively,
              as well as an understanding of practical multi-disciplinary
              concepts that he applies to his work in CueBack.
              <br />
              <br />
              Outside of academics, Daniel has gained experience as an Eagle
              Scout, and as the former President of the Cal Poly chapter of a
              business fraternity, which was recognized as having the top
              philanthropy programming in the nation over more than 350 other
              chapters. Daniel was born in Tokyo and speaks Japanese. He also
              managed a campus-wide event at his University that raised over
              $5,300 to provide more than 177 Ugandans access to clean water. In
              his current life, he has traveled to 36 foreign countries.
            </Body>
          </div>
        </ProfileGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    daniel: allFile(filter: { relativePath: { eq: "daniel-1.jpg" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default DanielPage
